  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-13-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert fissure Salon de Provence</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expert fissure Salon-de-Provence</h1>

<p>Si vous constatez des fissures sur votre ouvrage d’habitation, sur un mur de soutènement et que vous avez des questions légitimes sur l’état structurel du bien, les risques et les travaux nécéssaires pour y remédier, l’intervention d’un expert fissure pourrait s’avérer nécéssaire.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert fissure Salon de Provence" />
</div>


<p>L’Expert fissure détermine les causes et origines des fissures sur votre ouvrage et vous oriente sur les travaux à réaliser et leur coût.</p>

<p>L’intérêt est ici d’être accompagné par un Professionnel indépendant du bâtiment qui mettra à votre service ses compétences en pathologie du bâtiment et Ingénierie structurelle pour trouver la solution la plus adaptée à votre problématique. </p>

<h2>Que faire lorsque vous constatez des fissures ?</h2>

<p>Bien souvent, l’appel à un ami ou à une entreprise peut mal vous orienter sur la cause des fissurations et surtout, vous risquez d’être mal conseillé sur les travaux de reprise à entreprendre.</p>

<p>Il est bien plus judicieux, compte tenu de vos enjeux, d’être accompagné par un expert neutre, indépendant et compétent dans les techniques de la construction.</p>

<p>Lui seul est certifié et compétent afin de vous orienter sur les solutions techniques les plus adaptées à une réparation pérenne et durable des fissures affectant votre ouvrage. </p>

<h2>Notre prestation expertise fissure</h2>

<p>Si vous avez besoin d’être accompagné et d’obtenir un avis technique de qualité, vous pouvez faire appel à notre cabinet d’experts de la construction.</p>
<p>Nos expertises sont validées par un sapiteur ingénieur structure-béton armé</p>

<p>Nous accompagnons également l’assuré lors de litige avec un constructeur, une assurance afin de défendre ses intérêts.</p>


<p></p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert fissure</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-fissure-salon-de-provence/' className='active'>Expert fissure Salon de Provence</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details